
import { Watch } from 'vue-property-decorator'
import { mixins, Options } from 'vue-class-component'
import { maska } from 'maska'
import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'
import { constant, SCHEDULE_TYPE } from '@/utils/constants'
import { ACTION_APP, ACTION_PUSH, ACTION_SCENARIO_TEMPLATE } from '@/store/actions'
import { IPushCampaign, IPushCampaignDetail, IScenarioTemplate, IValidRequest } from '@/utils/types'
import FrequencyCapSetting from './setting/FrequencyCapSetting.vue'
import ScheduleSetting from './setting/ScheduleSetting.vue'
import AudienceOrNotSetting from './setting/AudienceOrNotSetting.vue'
import ScenariosMaker from '@/components/scenarios/ScenariosMaker.vue'
import PushCampaignMixin from './mixins/PushCampaignMixin.vue'
import TestModeSelector from '@/components/common/ui/TestModeSelector.vue'
import BaseFormMixin from '../common/mixins/BaseFormMixin.vue'
import CLoading from '@/components/common/ui/CLoading.vue'
import { ICard } from 'bot-flow-maker/src/types'
import { ObjectUtils } from '@/utils/objects'

@Options({
  components: {
    AudienceOrNotSetting,
    ScheduleSetting,
    FrequencyCapSetting,
    ScenariosMaker,
    TestModeSelector,
    CLoading,
  },
  directives: { maska },
  emits: [],
  async beforeRouteLeave() {
    if (!this) {
      return
    }
    this.isCancel = false
    const result = await this.checkDraftValue()
    if (result) {
      return true
    } else {
      return false
    }
  },
})
export default class PushCampaignSettingForm extends mixins(PushCampaignMixin, BaseFormMixin) {
  loading = false
  isScenarioEditingMode = false
  bar = 'setting'
  isTestMode = false
  isCopy = false
  scenarios: ICard[] = []
  directionType = '0'
  componentKey = 0

  form: IPushCampaign = {
    _id: '',
    title: '',
    app_id: '',
    delivered: 0,
    is_active: false,
    is_test_mode: false,
    audience_size: 0,
    prod_detail: {
      direction_type: 1,
      audience: {
        audience_or: [],
        audience_not: [],
      },

      frequency: {
        active: false,
        time_per_user: 1,
      },
      schedule: {
        schedule_type: '',
        schedule_by_type: '',
        delay_day: 0,
        day_of_week: 0,
        day_of_month: 1,
        date: '',
        time: '',
      },
      scenarios: [],
    },
    test_detail: {
      direction_type: 1,
      audience: {
        audience_or: [],
        audience_not: [],
      },

      frequency: {
        active: false,
        time_per_user: 1,
      },
      schedule: {
        schedule_type: '',
        schedule_by_type: '',
        delay_day: 0,
        day_of_week: 0,
        day_of_month: 1,
        date: '',
        time: '',
      },
      scenarios: [],
    },
  }

  get appId() {
    return this.$route.params.app_id
  }

  get tabs() {
    return this.bar
  }

  set tabs(tab: string) {
    this.bar = tab
  }

  get requiredRule() {
    const requiredRule = {
      campaignName: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.push.campaign_name'),
      }),
    }
    return requiredRule
  }

  get saveBtnText() {
    return this.$t('reflect')
  }

  get stateEdit() {
    return Object.keys(this.initForm).length && ObjectUtils.isDifference(this.parentForm, this.initForm)
  }

  async onCancel() {
    this.testMode = this.isTestMode
    this.goto('pushes', { app_id: this.selectedAppId })
  }

  async onRevert() {
    this.testMode = this.isTestMode
    this.isCancel = true
    const result = await this.checkDraftValueOnCampaign()
    if (result) {
      this.fetchSelectingPush()
    }
  }

  onSubmit() {
    if (!this.validate()) {
      return
    }

    this.$refs.formRef
      .validate()
      .then(async (success: boolean) => {
        if (!success) {
          return
        }

        this.loading = true
        // check limit 5 cards
        if (!(await this.checkSizeCardsOfScenarios())) {
          this.loading = false
          return
        }

        await this.doSave(false)
        this.loading = false
      })
      .catch((error: unknown) => {
        console.log('error', error)
      })
  }

  async checkSizeCardsOfScenarios() {
    if (this.isNew) {
      return true
    }
    const { dispatch } = this.$store
    const params: IValidRequest = {
      app_id: this.form.app_id ?? '',
      initial_id: '',
      push_id: this.form._id ?? '',
      card_id: '',
      card_type: '',
      total_messages: 0,
      campaign: this.form,
    }
    const resp = await dispatch(ACTION_APP.CHECK_LIMIT_CARDS, params)
    if (resp.count > 5) {
      const card = resp.card
      this.$refs.scenarioEditor.handleErrorCard(card.uniqueId)
      this.$q.dialog({
        title: this.$t('error'),
        message: this.$t('messages.size_must_be_between_1_and_5', {
          card_title: card.title,
          count: resp.count,
          campaign_type: resp.campaign_type,
          campaign_title: resp.campaign_title,
        }),
        persistent: true,
        html: true,
      })
      return false
    }
    return true
  }

  scenarioEditingMode(editing: boolean) {
    this.isScenarioEditingMode = editing
  }

  async validate() {
    let detail = this.form.prod_detail
    if (this.isTestMode) {
      detail = this.form.test_detail
    }

    if (detail?.scenarios?.length === 0) {
      this.$q.dialog({
        title: this.$t('error'),
        message: this.$t('validate.please_design_scenario'),
        persistent: true,
      })
      return false
    }

    return true
  }

  async onRunNow() {
    let detail = this.form.prod_detail
    if (this.isTestMode) {
      detail = this.form.test_detail
    }
    const schedule = detail?.schedule
    if (schedule) {
      const today = moment()
      schedule.schedule_type = SCHEDULE_TYPE.ONE_TIME
      schedule.date = today.format(constant.DATE_FORMAT)
      // Calculate the scheduled time, considering the possibility of exceeding 59 minutes
      let hour = today.hour()
      let minute = today.minute() + 10
      if (minute >= 60) {
        // Adjust the hour and reset the minute if it exceeds 59
        const additionalHours = Math.floor(minute / 60)
        hour += additionalHours
        minute %= 60
      }

      // Format the time with two digits for hour and minute
      const formattedHour = hour.toString().padStart(2, '0')
      const formattedMinute = minute.toString().padStart(2, '0')

      schedule.time = `${formattedHour}:${formattedMinute}`
      // schedule.time = `${hour}:${minute}`
    }

    if (!this.validate()) {
      return
    }

    this.$refs.formRef
      .validate()
      .then(async (success: boolean) => {
        if (!success) {
          return
        }

        this.$q
          .dialog({
            title: this.$t('confirm'),
            message: this.$t('messages.save_current_settings_and_send_immediately'),
            cancel: true,
            persistent: true,
          })
          .onOk(async () => {
            this.loading = true
            // check limit 5 cards
            if (!(await this.checkSizeCardsOfScenarios())) {
              this.loading = false
              return
            }

            await this.doSave(true)
            this.loading = false
          })
      })
      .catch((error: unknown) => {
        console.log('error', error)
      })
  }

  async doSave(isSendNow: boolean) {
    const { dispatch } = this.$store
    let success = false

    if (!this.form._id) {
      this.form.app_id = this.selectedAppId
      if (this.form.test_detail) {
        const detail: IPushCampaignDetail = {
          direction_type: this.form.prod_detail?.direction_type,
          scenarios: this.form.prod_detail?.scenarios,
          frequency: this.form.test_detail?.frequency,
          schedule: this.form.test_detail?.schedule,
          audience: this.form.test_detail.audience,
        }
        this.form.test_detail = detail
      }

      success = await dispatch(ACTION_PUSH.ADD, {
        ...this.form,
        is_send_now: isSendNow,
        is_send_test_mode: this.isTestMode,
      })
    } else {
      success = await dispatch(ACTION_PUSH.UPDATE, {
        ...this.form,
        is_send_now: isSendNow,
        is_send_test_mode: this.isTestMode,
      })
    }

    if (success) {
      let detail = this.form.prod_detail
      if (this.isTestMode) {
        detail = this.form.test_detail
      }

      // Warning messsage delay
      if (detail?.schedule?.schedule_by_type === 'by_delay_date') {
        const resp = await dispatch(ACTION_PUSH.WARNING_DELAY, {
          ...this.form,
        })
        // eslint-disable-next-line
        if (resp && resp['result']) {
          this.$q.notify({
            message: this.$t('label.push.no_users_targeted_for_delivery'),
            color: 'warning',
          })
        }
      }

      if (isSendNow) {
        this.$q.notify({
          message: this.$t('label.push.push_message_sent'),
          color: 'positive',
        })
      } else {
        this.$q.notify({
          message: this.$t('messages.saved'),
          color: 'positive',
        })
      }

      this.initForm = cloneDeep(this.parentForm)
      this.goto('pushes', { app_id: this.selectedAppId })
    }
  }

  onDetailCopy() {
    this.isCopy = !this.isCopy
  }

  @Watch('actionType', { immediate: true })
  async fetchSelectingPush() {
    if (!this.selectedAppId) {
      return
    }

    if (this.selectedCampaignId !== '') {
      const data: IPushCampaign = await this.$store.dispatch(ACTION_PUSH.LOAD_ITEM, this.selectedCampaignId)
      if (data) {
        this.form = { ...this.form, ...data }
        if (this.actionType === constant.ACTION_TYPE.COPY) {
          this.form._id = ''
          this.form.title = this.form.title + ' Copy'
          this.form.is_test_mode = false
          this.form.is_active = false
          this.form.story_id = ''
        }
      }
    }
    if (this.selectedScenarioTemplateId !== '') {
      const scenario_template: IScenarioTemplate = await this.$store.dispatch(
        ACTION_SCENARIO_TEMPLATE.LOAD_ITEM,
        this.selectedScenarioTemplateId
      )
      if (scenario_template) {
        if (this.form.test_detail) {
          this.form.test_detail.scenarios = scenario_template.scenario
        }
        if (this.form.prod_detail) {
          this.form.prod_detail.scenarios = scenario_template.scenario
        }
      }
    }

    this.parentForm = this.form
    this.initForm = cloneDeep(this.parentForm)
    this.onChangeTestMode()
  }

  onChangeDirection(value) {
    this.directionType = value
    if (this.isTestMode) {
      if (this.form.test_detail) {
        this.form.test_detail.direction_type = Number.parseInt(value)
      }
    } else {
      if (this.form.prod_detail) {
        this.form.prod_detail.direction_type = Number.parseInt(value)
      }
    }
  }

  onChangeScenarios(value) {
    this.scenarios = value
    if (this.isTestMode) {
      if (this.form.test_detail) {
        this.form.test_detail.scenarios = value
      }
    } else {
      if (this.form.prod_detail) {
        this.form.prod_detail.scenarios = value
      }
    }
  }

  editStart() {
    this.bar = 'setting'
  }

  @Watch('isTestMode', { immediate: true })
  async onChangeTestMode() {
    if (this.isTestMode) {
      if (this.form.test_detail) {
        this.scenarios = this.form.test_detail.scenarios ?? []
        this.directionType = this.form.test_detail.direction_type?.toString() ?? '0'
      }
    } else {
      if (this.form.prod_detail) {
        this.scenarios = this.form.prod_detail.scenarios ?? []
        this.directionType = this.form.prod_detail.direction_type?.toString() ?? '0'
      }
    }

    this.componentKey = this.componentKey + 1
  }

  handleLoading(val) {
    this.loading = val
  }

  async created() {
    // this.fetchSelectingPush()
    if (this.selectedCampaignId === '') {
      this.$nextTick(() => {
        this.$refs.formRef.validate()
      })
    }
    this.isCancel = false
  }
}
